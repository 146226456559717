import { getLocalViewAs } from "utils/localStorage";
import { Role } from "utils";

const superAdminMenu = [
  {
    _tag: "CSidebarNavItem",
    name: "Home",
    to: "/home",
    icon: "cil-home",
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Dashboards"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Analytics per Thriver",
    to: "/dashboard/thrivers",
    icon: "cil-speedometer",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Mood Analytics",
    to: "/dashboard/mood",
    icon: "cil-happy",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Engagement Analytics",
    to: "/dashboard/engagement",
    icon: "cil-thumb-up",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Analytics per Caregiver",
    to: "/dashboard/caregivers",
    icon: "cil-healing",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Thriving.ai Analytics",
    to: "/dashboard/thriving-ai",
    icon: "cil-user",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Health Check",
    to: "/dashboard/health-check",
    icon: "cil-user",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Smart Watch Analytics",
    to: "/dashboard/smart-watch",
    icon: "cil-watch",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Activity Log",
    to: "/dashboard/activity-log",
    icon: "cilFeaturedPlaylist",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Chatnels mood analytics",
    to: "/dashboard/chatnels-mood",
    icon: "cilFeaturedPlaylist",
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Content"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Learning Journeys",
    to: "/learning-journeys",
    icon: "cil-book",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Content",
    to: "/content",
    icon: "cil-folder",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Resources",
    to: "/resources",
    icon: "cil-description",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Version Control",
    to: "/version-control",
    icon: "cil-history",
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Circles"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Care Circles",
    to: "/care-circles",
    icon: "cil-circle",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Social Circles",
    to: "/social-circles",
    icon: "cil-circle",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Learning Circles",
    to: "/skill-circles",
    icon: "cil-circle",
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Management"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Users",
    to: "/users",
    icon: "cil-people",
  },
  {
    _tag: "CSidebarNavItem",
    name: "B2B clients",
    to: "/carehomes",
    icon: "cil-building",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Caregivers",
    to: "/caregivers",
    icon: "cil-life-ring",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Reports",
    to: "/reports",
    icon: "cil-x-circle",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Customer Journey",
    to: "/customer-journey",
    icon: "cil-briefcase",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Guides",
    to: "/guides",
    icon: "cil-info",
  },
];

const careProviderMenu = [
  {
    _tag: "CSidebarNavItem",
    name: "Home",
    to: "/home",
    icon: "cil-home",
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Dashboards"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Analytics per Thriver",
    to: "/dashboard/thrivers",
    icon: "cil-speedometer",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Mood Analytics",
    to: "/dashboard/mood",
    icon: "cil-happy",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Engagement Analytics",
    to: "/dashboard/engagement",
    icon: "cil-thumb-up",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Health Check",
    to: "/dashboard/health-check",
    icon: "cil-user",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Smart Watch Analytics",
    to: "/dashboard/smart-watch",
    icon: "cil-watch",
  },

  {
    _tag: "CSidebarNavItem",
    name: "Chatnels mood analytics",
    to: "/dashboard/chatnels-mood",
    icon: "cilFeaturedPlaylist",
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Circles"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Care Circles",
    to: "/care-circles",
    icon: "cil-circle",
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Management"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Users",
    to: "/users",
    icon: "cil-people",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Guides",
    to: "/guides",
    icon: "cil-info",
  },
];

const careHomeMenu = [
  {
    _tag: "CSidebarNavItem",
    name: "Home",
    to: "/home",
    icon: "cil-home",
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Dashboards"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Analytics per Thriver",
    to: "/dashboard/thrivers",
    icon: "cil-speedometer",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Mood Analytics",
    to: "/dashboard/mood",
    icon: "cil-happy",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Engagement Analytics",
    to: "/dashboard/engagement",
    icon: "cil-thumb-up",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Analytics per Caregiver",
    to: "/dashboard/caregivers",
    icon: "cil-healing",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Health Check",
    to: "/dashboard/health-check",
    icon: "cil-user",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Smart Watch Analytics",
    to: "/dashboard/smart-watch",
    icon: "cil-watch",
  },

  {
    _tag: "CSidebarNavItem",
    name: "Chatnels mood analytics",
    to: "/dashboard/chatnels-mood",
    icon: "cilFeaturedPlaylist",
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Content"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Content",
    to: "/content",
    icon: "cil-folder",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Resources",
    to: "/resources",
    icon: "cil-description",
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Circles"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Care Circles",
    to: "/care-circles",
    icon: "cil-circle",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Social Circles",
    to: "/social-circles",
    icon: "cil-circle",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Learning Circles",
    to: "/skill-circles",
    icon: "cil-circle",
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Management"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Users",
    to: "/users",
    icon: "cil-people",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Caregivers",
    to: "/caregivers",
    icon: "cil-life-ring",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Calendar",
    to: "/calendar",
    icon: "cil-calendar",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Records",
    to: "/activity-record",
    icon: "cil-pencil",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Guides",
    to: "/guides",
    icon: "cil-info",
  },
];

export function getNavigation({ location }) {
  setLocationPages(location);
  // If viewAs feature is active return careHome menu
  if (getLocalViewAs()?.identifier) return careHomeMenu;

  // Returns menu from dashboard type
  if (Role.adminAccess()) return superAdminMenu;
  if (Role.careHomeAccess()) return careHomeMenu;
  return careProviderMenu;
}

// Pages only for uk users
const iot = {
  _tag: "CSidebarNavItem",
  name: "Smart Devices",
  to: "/iot",
  icon: "cil-globe-alt",
};

export function setLocationPages(location) {
  const isFromUK = location === "UK";
  if (isFromUK) {
    if (canPushElement(iot, superAdminMenu)) superAdminMenu.push(iot);
    if (canPushElement(iot, careHomeMenu)) careHomeMenu.splice(16, 0, iot);
  }
}

const canPushElement = (element, menu = []) =>
  !Boolean(menu.find((x) => x.to === element.to));
