import { getLocalStorageBaseUrl } from "./localStorage";

export const getMediaURL = (image) =>
  `${getLocalStorageBaseUrl()}/secure/media/${image}`;

export const getErrorMessage = (error) => {
  return (
    error?.response?.data?.message ||
    error?.data?.message ||
    error?.message ||
    "Unknown error"
  );
};

export const objectsEqual = (o1, o2) =>
  Object.keys(o1).length === Object.keys(o2).length &&
  Object.keys(o1).every((p) => o1[p] === o2[p]);

export const arraysEqual = (a1, a2) =>
  a1.length === a2.length && a1.every((o, idx) => objectsEqual(o, a2[idx]));

export const sumObjectValues = (obj) =>
  Object.values(obj).reduce((a, b) => a + b || 0, 0);

export const sumArrayValues = (arr) => arr.reduce((a, b) => a + b || 0, 0);

export const getRandomId = () => "id" + Math.random().toString(16).slice(2);

export const arrayRotate = (arr, count) => {
  count -= arr.length * Math.floor(count / arr.length);
  arr.push.apply(arr, arr.splice(0, count));
  return arr;
};
export const categorizeWellness = (num) => {
  if (num >= 0 && num <= 3) {
    return 'Low';
  } else if (num >= 4 && num <= 6) {
    return 'Medium';
  } else if (num >= 7 && num <= 10) {
    return 'High';
  } else {
    return 'Na';
  }
}