import { lazy } from "react";

const HomePage = lazy(() => import("../pages/Home/UI/Home.view"));
// Profile
const ProfilePage = lazy(() => import("../pages/Profile/UI/AdminProfile.view"));
const UserProfilePage = lazy(() =>
  import("../pages/Profile/UI/UserProfile.view")
);
// Users
const PreviewPage = lazy(() =>
  import("../pages/UserPreview/UI/UserPreview.view")
);
const UsersPage = lazy(() => import("../pages/Users/UI/Users.view"));
const CaregiversPage = lazy(() =>
  import("../pages/Caregivers/UI/Caregivers.view")
);
const CareHomesPage = lazy(() =>
  import("../pages/Carehomes/UI/Carehomes.view")
);

// Circles
const CareCirclesPage = lazy(() =>
  import("../pages/CareCircles/UI/CareCircles.view")
);
const SocialCirclesPage = lazy(() =>
  import("../pages/SocialCircles/UI/SocialCircles.view")
);
const SkillCirclesPage = lazy(() =>
  import("../pages/SkillCircles/UI/SkillCircles.view")
);
// Learning Journeys
const LJPage = lazy(() =>
  import("../pages/LearningJourneys/UI/LearningJourneys.view")
);
const CreateLJPage = lazy(() =>
  import("../pages/LearningJourneys/UI/LJ/AddLJ.view")
);
const EditLJPage = lazy(() =>
  import("../pages/LearningJourneys/UI/LJ/EditLJ.view")
);
const StepsPage = lazy(() =>
  import("../pages/LearningJourneys/UI/Steps/Steps.view")
);
const EditStepPage = lazy(() =>
  import("../pages/LearningJourneys/UI/Steps/EditStep.view")
);

// Content space
const DataContentPage = lazy(() =>
  import("../pages/ContentSpace/UI/ContentSpace.view")
);
const CreateContentPage = lazy(() =>
  import("../pages/ContentSpace/UI/AddContent.view")
);
const EditContentPage = lazy(() =>
  import("../pages/ContentSpace/UI/EditContent.view")
);

// Resources
const ResourcesPage = lazy(() =>
  import("../pages/Resources/UI/Resources.view")
);
const CreateResourcePage = lazy(() =>
  import("../pages/Resources/UI/CreateResource.view")
);
const EditResourcePage = lazy(() =>
  import("../pages/Resources/UI/EditResource.view")
);

// Dashboards
const DashboardThriversPage = lazy(() =>
  import("../pages/Dashboards/views/AnalyticsPerThriver.view")
);
const DashboardCaregiverPage = lazy(() =>
  import("../pages/Dashboards/Caregivers/CaregiversAnalytics.view")
);
const DashboardMoodPage = lazy(() =>
  import("../pages/Dashboards/views/Mood.view")
);
const DashboardEngagementPage = lazy(() =>
  import("../pages/Dashboards/views/Engagement.view")
);
const DashboardHealthCheckPage = lazy(() =>
  import("../pages/Dashboards/views/HealthCheck.view")
);
const DashboardHealthDetailPage = lazy(() =>
  import("../pages/Dashboards/views/HealthDetails.view")
);
const DashboardSmartWatchPage = lazy(() =>
  import("../pages/Dashboards/views/SmartWatch.view")
);
const DashboardSmartWatchGraphPage = lazy(() =>
  import("../pages/Dashboards/views/SmartWatchGraph.view")
);
const ThrivingAIAnalyticsPage = lazy(() =>
  import("../pages/Dashboards/ThrivingAI/ThrivingAI.view")
);

const DashboardActivityLogPage = lazy(() =>
  import("../pages/Dashboards/views/ActivityLog.view")
);

const DashboardActivityLogDetailsPage = lazy(() =>
  import("../pages/Dashboards/views/ActivityLogDetails.view")
);

const ChatnelsMoodPage = lazy(() =>
  import("../pages/Dashboards/views/ChatnelsMood.view")
);

const ChatnelsDetailPage = lazy(() =>
  import("../pages/Dashboards/views/ChatnelsDetails.view")
);

// Calendar
const CalendarPage = lazy(() => import("../pages/Calendar/UI/Calendar.view"));
const CalendarCreatePage = lazy(() =>
  import("../pages/Calendar/UI/CreateEvent.view")
);
const CalendarEditPage = lazy(() =>
  import("../pages/Calendar/views/EditEvent.view")
);
const CalendarEventsPage = lazy(() =>
  import("../pages/Calendar/UI/Events.view")
);

// Records
const RecordsPage = lazy(() => import("../pages/Records/UI/Records.view"));

// Internet of Things
const IoTPage = lazy(() => import("../pages/IoT/UI/IoT.view"));
const IoTLinkPage = lazy(() => import("../pages/IoT/UI/LinkDevice.view"));
const IoTEditPage = lazy(() => import("../pages/IoT/UI/EditDevice.view"));
const IoTLocationsPage = lazy(() => import("../pages/IoT/UI/Locations.view"));

// Reports
const ReportsPage = lazy(() => import("../pages/Reports/UI/Reports.view"));

// Version control
const VersionControlPage = lazy(() =>
  import("../pages/VersionControl/UI/VersionControl.view")
);
// Reports
const CustomerJourneyPage = lazy(() =>
  import("../pages/CustomerJourney/UI/CustomerJourney.view")
);
// Guides
const GuidesPage = lazy(() => import("../pages/Guides/UI/Guides.view"));
// Care plan
const CarePlanPage = lazy(() => import("../pages/CarePlan/UI/CarePlan.view"));

export {
  HomePage,
  ProfilePage,
  PreviewPage,
  UserProfilePage,
  UsersPage,
  CaregiversPage,
  CareHomesPage,
  CareCirclesPage,
  SocialCirclesPage,
  SkillCirclesPage,
  LJPage,
  CreateLJPage,
  EditLJPage,
  StepsPage,
  EditStepPage,
  DataContentPage,
  CreateContentPage,
  EditContentPage,
  ResourcesPage,
  CreateResourcePage,
  EditResourcePage,
  DashboardThriversPage,
  DashboardCaregiverPage,
  DashboardMoodPage,
  DashboardEngagementPage,
  DashboardHealthCheckPage,
  DashboardHealthDetailPage,
  DashboardSmartWatchPage,
  DashboardSmartWatchGraphPage,
  ThrivingAIAnalyticsPage,
  CalendarPage,
  CalendarCreatePage,
  CalendarEditPage,
  CalendarEventsPage,
  RecordsPage,
  IoTPage,
  IoTLinkPage,
  IoTEditPage,
  IoTLocationsPage,
  ReportsPage,
  VersionControlPage,
  CustomerJourneyPage,
  GuidesPage,
  CarePlanPage,
  DashboardActivityLogPage,
  DashboardActivityLogDetailsPage,
  ChatnelsMoodPage,
  ChatnelsDetailPage,
};
